import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import SelectDropdown from "../../components/common/SelectDropdown";
import statusModel from "../../models/status.model";
import { toast } from "react-toastify";
import { search_success } from "../../actions/search";
import environment from "../../environment";
import Pagination from "react-pagination-js";

const LogoListing = () => {
    const user = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const searchState = useSelector((state) => state.search)
    const [data, setdata] = useState()
    const [filter, setfilter] = useState({ page: 1, count: 20, search: '', status: '', qr_code_type_id: '' })
    const history = useHistory()
    const [loading, setloading] = useState(false)
    const [total, settotal] = useState(0)
    // const [category, setcategory] = useState()
    const [qrcodetypes, setqrcodetypes] = useState()
    const [deleteId, setdeleteId] = useState()

    useEffect(() => {
        getdata()
        // getCategory()
        getQrcodetype()
    }, [])

    useEffect(() => {
        if (user && user.loggedIn) {
            setfilter({ ...filter, search: searchState.data });
            getdata({ search: searchState.data, page: 1 });
        }
    }, [searchState]);

    const getdata = (p = {}) => {
        let fil = { ...filter, ...p }
        setloading(true)
        ApiClient.get(`logo/all`, fil).then(res => {
            if (res.success) {
                setdata(res.data.data)
                settotal(res.data.total)
            }
            setloading(false)
        })
    }

    const getQrcodetype = () => {
        ApiClient.get(`qrtype/all?status=active`).then(res => {
            if (res.success) {
                setqrcodetypes(res.data.map(item => {
                    return ({ id: item?._id, name: item?.type })
                }))
            }
        })
    }

    // const getCategory = () => {
    //     ApiClient.get(`common-category/all`).then(res => {
    //         if (res.success) {
    //             setcategory(res.data.map(item => {
    //                 return ({ id: item?._id, name: item?.name })
    //             }))
    //         }
    //     })
    // }

    const statusChange = (item) => {
        let status = 'active';
        if (item.status == 'active') status = 'deactive';
        if (window.confirm(`Do you want to ${status == 'active' ? 'Activate' : 'Deactivate'} this`)) {
            loader(true);
            ApiClient.put(`change/status?model=logos&status=${status}&id=${item?.id}`).then((res) => {
                if (res.success) {
                    toast.success(res.message)
                    getdata();
                }
                loader(false);
            }
            )
        }
    }

    const handleAction = (id, key) => {
        if (key == 'view') {
            history.push(`/logo/detail/${id}`)
        } else if (key == 'edit') {
            history.push(`/logo/edit/${id}`)
        } else {
            ApiClient.delete(`logo?id=${id}`).then(res => {
                if (res.success) {
                    toast.success(res.message)
                    getdata()
                    document.getElementById('CloseDeleteModal').click()
                }
            })
        }
    }

    const handleFilters = (p = {}) => {
        setfilter({ ...filter, ...p })
        getdata(p)
    }

    const pageChange = (e) => {
        setfilter({ ...filter, page: e });
        getdata({ page: e });
    }

    const clearAllFilters = () => {
        let p = { page: 1, count: 20, search: '', status: '', qr_code_type_id: '' }
        setfilter({ ...filter, ...p })
        dispatch(search_success(''))
        getdata(p)
    }

    return (
        <>
            <Layout>
                <div className="d-flex justify-content-between">
                    <h1>Logo</h1>
                    <div className="d-flex">
                        <Link to={`/logo/add`} className="btn btn-primary h-75">Add Logo</Link>
                        <span className="mx-2">
                            <SelectDropdown
                                isSingle={true}
                                id="statusDropdown"
                                displayValue="name"
                                placeholder="Select Status"
                                className="mr-2"
                                intialValue={filter.status}
                                result={e => handleFilters({ status: e.value })}
                                options={statusModel.list}
                            />
                        </span>
                        <SelectDropdown
                            isSingle={true}
                            id="statusDropdown"
                            displayValue="name"
                            placeholder="All Qr Code Type"
                            intialValue={filter.qr_code_type_id}
                            result={e => handleFilters({ qr_code_type_id: e.value })}
                            options={qrcodetypes}
                        />
                        {/* <SelectDropdown
                            isSingle={true}
                            id="statusDropdown"
                            displayValue="name"
                            placeholder="All Categories"
                            intialValue={filter.category_id}
                            result={e => handleFilters({ category_id: e.value })}
                            options={category}
                        /> */}

                        {filter?.status || filter?.search || filter?.qr_code_type_id ?
                            <button className="btn btn-secondary ml-2 h-75" onClick={e => clearAllFilters()}>Reset</button>
                            : null}
                    </div>
                </div>
                <table className="table mt-2">
                    <thead>
                        <tr>
                            <th scope="col">Image</th>
                            <th scope="col">Title</th>
                            <th scope="col">Qr Code Type</th>
                            <th scope="col">Status</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading && data && data.map(item => {
                            return <tr>
                                <td scope="row" className="pointer" onClick={e => handleAction(item?.id, 'view')}>
                                    {item?.image ?
                                        <img src={`${environment?.api}images/logo/${item?.image}`} width='50px' height='50px' />
                                        :
                                        <img src='/assets/img/placeholder.png' className="profileImage" />
                                    }
                                </td>
                                <td className="text-capitalize">{item?.title}</td>
                                <td className="text-capitalize">{item?.qr_code_type}</td>
                                <td>
                                    <div className={`user_hours ${item.status}`} onClick={() => statusChange(item)}>
                                        <span className='contract text-capitalize pointer'>
                                            {item.status == 'deactive' ? 'inactive' : 'active'}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div className="action_icons">
                                        <a className='edit_icon pointer' title="View" onClick={e => handleAction(item?.id, 'view')}>
                                            <i class="material-icons view" title="View">visibility</i>
                                        </a>
                                        <a className='edit_icon pointer' title="Edit" onClick={e => handleAction(item?.id, 'edit')}>
                                            <i class="material-icons edit" title="Edit">edit</i>
                                        </a>
                                        <span className='edit_icon pointer' onClick={e => setdeleteId(item?.id)} data-toggle="modal" data-target="#exampleModal">
                                            <i class="material-icons delete text-danger" title='Delete'> delete</i>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
                {!loading && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}
                {
                    !loading && total > filter.count ? <div className='paginationWrapper'>
                        <span>Showing {data?.length} from {total} records</span>
                        <Pagination
                            currentPage={filter.page}
                            totalSize={total}
                            sizePerPage={filter.count}
                            changeCurrentPage={pageChange}
                        />
                    </div> : <></>
                }
                {loading ? <div className="text-center py-4">
                    <img src="/assets/img/loader.gif" className="pageLoader" />
                </div> : <></>}
            </Layout>

            {/* Delete Modal */}
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <button type="button" class="close" id="CloseDeleteModal" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className='text-center mt-4'>
                                <p>Are you sure you want to delete this?</p>
                                <button type="button" class="btn btn-danger" onClick={e => handleAction(deleteId, 'delete')}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default LogoListing