import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";

const FAQDetail = () => {
    const { id } = useParams()
    const [detail, setdetail] = useState()
    const history = useHistory()

    useEffect(() => {
        getDetail()
    }, [])

    const getDetail = () => {
        ApiClient.get(`faq?id=${id}`).then(res => {
            if (res.success) {
                setdetail(res.data)
            }
        })
    }

    return (
        <>
            <Layout>
                <div className="d-flex justify-content-between align-items-center">
                    <h1>FAQ Detail</h1>
                    <div>
                        <i class="fa fa-pen mr-4 h5" title="Edit" aria-hidden="true" onClick={e => { history.push(`/faq/edit/${id}`) }}></i>
                        <i class="fa fa-arrow-left mr-2 h5" title="Back" aria-hidden="true" onClick={e => { history.goBack() }}></i>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-12">
                        <label><b>Question:</b> {detail?.question}</label>
                    </div>
                    <div className="col-md-12">
                        <label><b>Answer:</b> <span dangerouslySetInnerHTML={{ __html: detail?.answer }}></span></label>
                    </div>
                    <div className="col-md-12">
                        <label><b>Status:</b> {detail?.status}</label>
                    </div>
                    <div className="col-md-12">
                        <label><b>Category:</b> {detail?.category?.name}</label>
                    </div>
                    <div className="col-md-12">
                        <label><b>Date:</b> {moment(detail?.createdAt).format('DD MMM YYYY')}</label>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default FAQDetail