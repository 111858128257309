import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { toast } from "react-toastify";

const AddEditFAQCategory = () => {
    const { id } = useParams()
    const [form, setform] = useState({ name: '', type: 'faq' })
    const history = useHistory()

    useEffect(() => {
        if (id) {
            loader(true)
            ApiClient.get(`common-category?id=${id}`).then(res => {
                if (res.success) {
                    setform(res.data)
                }
                loader(false)
            })
        }
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        let payload = {
            ...form,
        }
        if (!id) {
            ApiClient.post(`common-category`, payload).then(res => {
                if (res.success) {
                    setTimeout(() => {
                        toast.success(res.message)
                    }, 100);
                    history.push(`/categories`)
                }
            })
        } else {
            payload = {
                ...payload,
                id: id
            }
            ApiClient.put(`common-category`, payload).then(res => {
                if (res.success) {
                    setTimeout(() => {
                        toast.success(res.message)
                    }, 100);
                    history.push(`/categories`)
                }
            })
        }
    }

    return (
        <>
            <Layout>
                <div className="d-flex justify-content-between">
                    <h1>{!id ? 'Add' : 'Edit'} FAQ Category</h1>
                </div>
                <form className="mt-5" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-12">
                            <label>Name</label>
                            <input type='text' value={form?.name} onChange={e => setform({ ...form, name: e.target.value })} className="form-control" placeholder="Enter Name" required />
                        </div>
                    </div>
                    <div className="mt-3 text-right">
                        <button type="button" className="btn btn-secondary discard mr-2" onClick={e => { history.goBack() }}>Back</button>
                        <button type="submit" className="btn btn-primary ml-2">{!id ? 'Save' : 'Update'}</button>
                    </div>
                </form>
            </Layout>
        </>
    )
}

export default AddEditFAQCategory