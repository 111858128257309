import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Layout from '../../../components/global/layout';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
import './style.scss';
import { useSelector } from 'react-redux';
import methodModel from '../../../methods/methods';
import { Link } from 'react-router-dom';

const UserDetail = (p) => {
    const { id } = useParams()
    const history = useHistory()
    const user = useSelector(state => state.user)
    const [data, setData] = useState()

    const getDetail = () => {
        loader(true)
        ApiClient.get(`user/detail`, { id: id }).then(res => {
            if (res.success) {
                setData(res.data)
            }
            loader(false)
        })
    }

    const back = () => {
        history.goBack()
    }

    useEffect(() => {
        getDetail()
    }, [])

    return (
        <Layout>
            <div className="text-right">
                <div className="d-flex justify-content-between align-items-center">
                    <h2 className="Profilehedding mt-3">User Detail</h2>
                    <div className='d-flex'>
                    <Link to={`/users/edit/${data?.id}`}><i className="fa fa-pen mr-4 mb-3" title='Edit' aria-hidden="true"></i></Link>
                    <Link to="/users"><i className="fa fa-arrow-left mr-4 mb-3" title='Back' aria-hidden="true"></i></Link>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="sideclass col-md-12">
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Name</label>
                            <div className='profiledetailscls'>{data && data.fullName}</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Mobile No</label>
                            <div className='profiledetailscls'>{data && data.mobileNo}</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Email</label>
                            <div className='profiledetailscls'>{data && data.email}</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Image</label>
                            <div>
                                <div className="imagethumbWrapper">
                                    <img src={methodModel.noImg(data && data.image, 'users')} className="thumbnail" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    );
};

export default UserDetail;
