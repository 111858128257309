import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import { Editor } from '@tinymce/tinymce-react';
import environment from "../../environment";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { toast } from "react-toastify";

const AddEditBlog = () => {
    const { id } = useParams()
    const [form, setform] = useState({ heading: '', description: '', image: '' })
    const [editor, seteditor] = useState()
    const history = useHistory()

    const uploadImage = (e) => {
        setform({ ...form, baseImg: e.target.value })
        let files = e.target.files
        let file = files.item(0)
        loader(true)
        ApiClient.postFormData('upload/image?modelName=blog', { file: file, modelName: 'blog' }).then(res => {
            if (res.success) {
                let image = res.data.fullpath
                setform({ ...form, image: image, baseImg: '' })
            } else {
                setform({ ...form, baseImg: '' })
            }
            loader(false)
        })
    }

    useEffect(() => {
        if (id) {
            ApiClient.get(`blog?id=${id}`).then(res => {
                if (res.success) {
                    setform(res.data)
                    seteditor(res.data.description)
                }
            })
        }
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        let payload = {
            ...form,
            description: editor
        }
        if (!id) {
            ApiClient.post(`blog`, payload).then(res => {
                if (res.success) {
                    setTimeout(() => {
                        toast.success(res.message)
                    }, 100);
                    history.push(`/blog`)
                }
            })
        } else {
            payload = {
                ...payload,
                id: id
            }
            ApiClient.put(`blog`, payload).then(res => {
                if (res.success) {
                    setTimeout(() => {
                        toast.success(res.message)
                    }, 100);
                    history.push(`/blog`)
                }
            })
        }
    }

    return (
        <>
            <Layout>
                <div className="d-flex justify-content-between">
                    <h1>{!id ? 'Add' : 'Edit'} Blog</h1>
                </div>
                <form className="mt-3" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-12">
                            <label>Author Name <span className="text-danger">*</span></label>
                            <input type='text' className='form-control' value={form?.author} onChange={e => setform({ ...form, author: e.target.value })} placeholder="Enter Author Name" required />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label>Title <span className="text-danger">*</span></label>
                            <input type='text' className='form-control' maxLength='100' value={form?.heading} onChange={e => setform({ ...form, heading: e.target.value })} placeholder="Enter Title" required />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label>Description</label>
                            <Editor
                                apiKey={environment?.tinymceApiKey}
                                initialValue={form?.description}
                                onEditorChange={(newValue, editor) => seteditor(newValue)}
                                init={{
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | formatselect | ' +
                                        'bold italic backcolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label>Image</label>
                            <div className='maininutcls mt-2'>
                                <label className="profileImageLabel">
                                    {form?.image ?
                                        <img src={`${environment?.api}images/blog/${form?.image}`} className="profileImage" />
                                        :
                                        <img src='/assets/img/placeholder.png' className="profileImage" />
                                    }
                                </label>
                                <div className='profile_btn'>
                                    <div>
                                        <label className="btn btn-primary edit ml-3">
                                            <input id="bannerImage" type="file" className="d-none" accept="image/*" value={form.baseImg ? form.baseImg : ''} onChange={(e) => { uploadImage(e); }} />{form.image ? 'Change' : 'Upload'} Image</label>
                                    </div>
                                    <div>
                                        {form.image ? <label className="btn btn-primary  delete ml-3" onClick={e => setform({ ...form, image: "" })}>Remove Image</label> : <></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 text-right">
                        <button type="button" className="btn btn-secondary discard mr-2" onClick={e => { history.goBack() }}>Back</button>
                        <button type="submit" className="btn btn-primary ml-2">{!id?'Save':'Update'}</button>
                    </div>
                </form>
            </Layout>
        </>
    )
}

export default AddEditBlog