import React from 'react';
import Layout from '../../components/global/layout';
import Pagination from "react-pagination-js";
import './style.scss';
import methodModel from '../../methods/methods';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const Html = ({
    edit,
    reset,
    ChangeStatus,
    pageChange,
    deleteUser,
    filters,
    loaging,
    data,
    statusChange,
    searchState,
    view,
    total = { total }
}) => {
    const [deleteId, setdeleteId] = useState()

    return (
        <Layout>
            <div className="d-flex justify-content-between align-items-center">
                <h3 className="hedding">Users</h3>
                <article className="d-flex mb-2">
                    <Link to={`/users/add`} className='btn btn-primary'>Add User</Link>
                    <div className="dropdown ml-2">
                        <button className="btn btn-primary dropdown-toggle removeBg" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {filters.status ? filters.status == "deactive" ? "Inactive" : filters.status : 'All Status'}
                        </button>
                        <div className="dropdown-menu shadow bg_hover" aria-labelledby="dropdownMenuButton">
                            <a className={filters.status == '' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("")}>All Status</a>
                            <a className={filters.status == 'active' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("active")} >Active</a>
                            <a className={filters.status == 'Inactive' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("deactive")} >Inactive</a>
                        </div>
                    </div>
                    {filters.status || searchState ? <>
                        <a className="btn btn-secondary ml-2 text-light" onClick={e => reset()}>
                            Reset
                        </a>
                    </> : <></>}
                </article>
            </div>
            <div className="table-responsive table_section mt-1">
                <table class="table table-striped">
                    <thead className='table_head'>
                        <tr className='heading_row'>
                            <th scope="col" className='table_data pointer' >Image</th>
                            <th scope="col" className='table_data pointer' >Name</th>
                            <th scope="col" className='table_data pointer' >Email </th>
                            <th scope="col" className='table_data pointer' >Mobile Number</th>
                            <th scope="col" className='table_data pointer' >Date</th>
                            <th scope="col" className='table_data pointer' >Status</th>
                            <th scope="col" className='table_data'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loaging && data && data.map((itm, i) => {
                            return <tr className='data_row'>
                                <td className='table_dats' onClick={e => view(itm.id)}>
                                    <img src={methodModel.userImg(itm.image)} className="user_imgs" width='50px' height='50px' />
                                </td>
                                <td className='table_dats'>{itm?.fullName}</td>
                                <td className='table_dats'>{itm?.email}</td>
                                <td className='table_dats'>{itm?.mobileNo || '--'}</td>
                                <td className='table_dats'>{moment(itm?.createdAt).format('DD MMM YYYY')}</td>
                                <td className='table_dats'><div className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                                    <span className='contract text-capitalize pointer'>
                                        {itm.status == 'deactive' ? 'inactive' : 'active'}
                                    </span>
                                </div></td>
                                <td className='table_dats'>
                                    <div className="action_icons">
                                        <a className='edit_icon pointer' title="View" onClick={e => view(itm.id)}>
                                            <i class="material-icons view" title="View">visibility</i>
                                        </a>
                                        <a className='edit_icon pointer' title="Edit" onClick={e => edit(itm.id)}>
                                            <i class="material-icons edit" title="Edit">edit</i>
                                        </a>
                                        <span className='edit_icon pointer' onClick={e => setdeleteId(itm.id)} data-toggle="modal" data-target="#exampleModal">
                                            <i class="material-icons delete text-danger" title='Delete'> delete</i>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        })
                        }
                    </tbody>
                </table>
            </div>

            {!loaging && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}
            {
                !loaging && total > filters.count ? <div className='paginationWrapper'>
                    <span>Showing {data?.length} from {total} records</span>
                    <Pagination
                        currentPage={filters.page}
                        totalSize={total}
                        sizePerPage={filters.count}
                        changeCurrentPage={pageChange}
                    />
                </div> : <></>
            }
            {loaging ? <div className="text-center py-4">
                <img src="/assets/img/loader.gif" className="pageLoader" />
            </div> : <></>}

            {/* Delete Modal */}
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <button type="button" class="close" id="CloseDeleteModal" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className='text-center mt-4'>
                                <p>Are you sure you want to delete user?</p>
                                <button type="button" class="btn btn-danger" onClick={e => deleteUser(deleteId)}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Html;
