import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Layout from "../../components/global/layout";
import environment from "../../environment";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";

const CookiePolicy = () => {
    const [form, setform] = useState({ heading: '', description: '', editor: '', id: '' })

    const handlePage = (e) => {
        e.preventDefault()
        let payload = {
            heading: form?.heading,
            description: form?.editor,
            type: 'cookie_policy',
            id: form?.id,
        }
        if (!form?.editor) {
            toast.error('Description is required')
            return
        }
        loader(true)
        ApiClient.put(`pages`, payload).then(res => {
            if (res.success) {
                toast.success(res.message)
            }
            loader(false)
        })
    }

    useEffect(() => {
        getDetails()
    }, [])

    const getDetails = () => {
        loader(true)
        ApiClient.get(`page?type=cookie_policy`).then(res => {
            if (res.success) {
                setform({
                    heading: res?.data?.heading,
                    description: res?.data?.description,
                    editor: res?.data?.editor,
                    id: res?.data?.id
                })
            }
            loader(false)
        })
    }

    return (
        <>
            <Layout>
                <div>
                    <h1>Cookie Policy</h1>
                </div>
                <div className="row mt-4">
                    <form onSubmit={handlePage}>
                        <div className="col-md-12 my-2">
                            <label>Heading</label>
                            <input type='text' value={form?.heading} onChange={e => setform({ ...form, heading: e.target.value })} className="form-control" placeholder="Heading" required />
                        </div>
                        <div className="col-md-12 my-2">
                            <label>Description</label>
                            <Editor
                                apiKey={environment?.tinymceApiKey}
                                initialValue={form?.description}
                                onEditorChange={(newValue, editor) => setform({ ...form, editor: newValue })}
                                init={{
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | formatselect | ' +
                                        'bold italic backcolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />
                        </div>
                        <div className="col-md-12 my-2 text-right">
                            <button className="btn btn-primary">Update</button>
                        </div>
                    </form>
                </div>
            </Layout>
        </>
    )
}

export default CookiePolicy