import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import environment from "../../environment";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";

const BlogDetail = () => {
    const [detail, setdetail] = useState()
    const { id } = useParams()
    const history = useHistory()

    useEffect(() => {
        getDetail()
    }, [])

    const getDetail = () => {
        loader(true)
        ApiClient.get(`blog?id=${id}`).then(res => {
            if (res.success) {
                setdetail(res.data)
            }
            loader(false)
        })
    }

    return (
        <>
            <Layout>
                <div className="d-flex justify-content-between align-items-center">
                    <h1>Blog Detail</h1>
                    <div>
                        <i class="fa fa-pen mr-4 h5" title="Edit" aria-hidden="true" onClick={e => { history.push(`/blog/edit/${id}`) }}></i>
                        <i class="fa fa-arrow-left mr-2 h5" title="Back" aria-hidden="true" onClick={e => { history.goBack() }}></i>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-12">
                        <label className="fs-6"><b>Author:</b> {detail?.author}</label>
                    </div>
                    <div className="col-md-12">
                        <label className="fs-6"><b>Title:</b> {detail?.heading}</label>
                    </div>
                    <div className="col-md-12">
                        <label className="fs-6"><b>Description:</b> <span dangerouslySetInnerHTML={{ __html: detail?.description }} ></span></label>
                    </div>
                    <div className="col-md-12">
                        <label className="fs-6"><b>Date:</b> {moment(detail?.createdAt).format('DD MMM YYYY')}</label>
                    </div>
                    <div className="col-md-12">
                        <label className="fs-6"><b>Image:</b><br />
                            {detail?.image ?
                                <img src={`${environment?.api}images/blog/${detail?.image}`} width='450px' height='350px' />
                                :
                                <img src='/assets/img/placeholder.png' width='200px' height='200px' />
                            }
                        </label>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default BlogDetail;