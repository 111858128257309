import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";

const PlanDetails = () => {
    const { id } = useParams()
    const [detail, setdetail] = useState()
    const history = useHistory()

    useEffect(() => {
        getDetail()
    }, [])

    const getDetail = () => {
        loader(true)
        ApiClient.get(`subscription-plan?id=${id}`).then(res => {
            if (res.success) {
                setdetail(res.data)
            }
            loader(false)
        })
    }

    return (
        <Layout>
            <div className="d-flex justify-content-between align-items-center">
                <h1>Plan Detail</h1>
                <div>
                    <i class="fa fa-pen mr-4 h5" title="Edit" aria-hidden="true" onClick={e => { history.push(`/plan/edit/${id}`) }}></i>
                    <i class="fa fa-arrow-left mr-2 h5" title="Back" aria-hidden="true" onClick={e => { history.goBack() }}></i>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-6 mb-2">
                    <label className="text-capitalize"><b>Name:</b> {detail?.name}</label>
                </div>
                <div className="col-md-6 mb-2">
                    <label className="text-capitalize"><b>Description:</b> {detail?.description}</label>
                </div>
                <div className="col-md-6 mb-2">
                    <label className="text-capitalize"><b>Status:</b> {detail?.status}</label>
                </div>
                <div className="col-md-6 mb-2">
                    <label><b>Date:</b> {moment(detail?.createdAt).format('DD MMM YYYY')}</label>
                </div>
                <div className="col-md-6 mb-2">
                    <label><b>Static QR Code:</b> {detail?.static_qr_code}</label>
                </div>
                <div className="col-md-6 mb-2">
                    <label><b>Dynamic QR Code:</b> {detail?.dynamic_qr_code}</label>
                </div>
                <div className="col-md-6 mb-2">
                    <label><b>Scans:</b> {detail?.scans}</label>
                </div>
                <div className="col-md-6 mb-2">
                    <label><b>Users:</b> {detail?.users}</label>
                </div>
                <div className="col-md-6 mb-2">
                    <label><b>Trial Period Days:</b> {detail?.trial_period_days}</label>
                </div>
            </div>
            <div className="row">
                <h3 className="mb-2">Pricing</h3>
                <div className="col-md-6 mb-2">
                    <label>{detail?.pricing.map(item => {
                        return <div>
                            <label className="mr-2"><b>Month:</b> {item?.interval_count}</label>
                            <label><b>Amount:</b> {item?.unit_amount} </label>
                        </div>
                    })}</label>
                </div>
            </div>
            {/* <div className="row">
                <h3 className="mb-2">Features</h3>
                <div className="col-md-6 mb-2">
                    <label><input type='checkbox' checked={detail?.unlimited_dynamic_qr_code} readOnly className="ch3" /> <b>Unlimited Dynamic Qr Code</b></label>
                </div>
                <div className="col-md-6 mb-2">
                    <label><input type='checkbox' checked={detail?.unlimited_scans} readOnly className="ch3" /> <b>Unlimited Scans</b></label>
                </div>
                <div className="col-md-6 mb-2">
                    <label><input type='checkbox' checked={detail?.unlimited_static_qr_code} readOnly className="ch3" /> <b>Unlimited Static Qr Code</b></label>
                </div>
                <div className="col-md-6 mb-2">
                    <label><input type='checkbox' checked={detail?.unlimited_users} readOnly className="ch3" /> <b>Unlimited Users</b></label>
                </div>
            </div> */}
        </Layout>
    )
}

export default PlanDetails