import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Layout from '../../components/global/layout';
import './style.scss';
import ApiClient from '../../methods/api/apiClient';

const Dashboard = () => {
  const user = useSelector((state) => state.user);
  const [data, setdata] = useState({ totalUsers: 0, activeUsers: 0 })

  useEffect(()=>{
    getUsersData()
  },[])

  const getUsersData=()=>{
    ApiClient.get(`users/active-total`).then(res=>{
      if(res.success){
        setdata({ totalUsers: res?.data?.totalUsers, activeUsers: res?.data?.totalActiveUsers })
      }
    })
  }

  return (
    <Layout>
      <div className="row d-flex align-items-center">
        <div className="col-md-9 head_cls">
          <h2 className="mb-1 mainHeading_cls">
            {' '}
            <span className="dsh_hedding">Hi</span>
            {user && user.fullName}
          </h2>
        </div>
        <div className="cards_sales">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3">
              <div className="sales_section">
                <div className="main_sales">
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Total Users</p>
                    </div>
                    <div>
                      <img src="/assets/img/send.png" className="wallets_img" />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">{data?.totalUsers}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mt-3">
              <div className="sales_section">
                <div className="main_sales">
                  <div className="sales_headings">
                    <div>
                      <p className="sub_heading">Active Users</p>
                    </div>
                    <div>
                      <img src="/assets/img/bag.png" className="wallets_img" />
                    </div>
                  </div>
                  <div className="sales_icons">
                    <h3 className="Amount_sales">{data?.activeUsers}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
