import React from "react";
import ImageUpload from "../../../components/common/ImageUpload";
import Layout from "../../../components/global/layout";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Html = ({ form, handleSubmit, setform, images, imageResult, back, handleEmailValidation, Err }) => {

    return <>
        <Layout>
            <form onSubmit={handleSubmit}>
                <div className="pprofile1">
                    <h3 className='ViewUser'>{form && form.id ? 'Edit' : 'Add'} User</h3>
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label>Name <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter User Name"
                                value={form.fullName}
                                onChange={e => setform({ ...form, fullName: e.target.value })}
                                required
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Email <span className="text-danger">*</span></label>
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Enter User Email"
                                value={form.email}
                                onChange={e => { handleEmailValidation(e) }}
                                required
                            />
                            {Err?<span className="text-danger">Please enter a valid email</span>:null}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Mobile No<span className="star">*</span></label>
                            <PhoneInput
                                className='w-100'
                                country={'us'}
                                value={form.mobileNo}
                                onChange={phone => setform({ ...form, mobileNo: phone })}
                            />
                        </div>
                        <div className="col-md-6  mb-3">
                            <label className='lablefontcls'>Image</label><br></br>
                            <ImageUpload model="users" result={e => imageResult(e, 'image')} value={images.image || form.image} multiple={false} />
                        </div>
                    </div>
                    <div className="text-right">
                        <button type="button" className="btn btn-secondary discard mr-2" onClick={e => back()}>Back</button>
                        <button type="submit" className="btn btn-primary">{form && form.id?'Update':'Save'}</button>
                    </div>
                </div>
            </form>
        </Layout>
    </>
}

export default Html