import React from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import moment from "moment";
import { Link } from "react-router-dom";
import environment from "../../environment";
import statusModel from "../../models/status.model";
import SelectDropdown from "../../components/common/SelectDropdown";

const Html = ({
    data,
    editType,
    deleteType,
    statusChange,
    loading,
    total,
    filter,
    pageChange,
    handleFilters,
    category,
    clearAllFilters,
    handleExport,
    setdeleteId,
    deleteId,
    dragStart,
    dragEnter,
    drop,
}) => {
    return (
        <>
            <Layout>
                <div className="d-flex justify-content-between">
                    <h1>Qr Code Types</h1>
                    <div className="d-flex">
                        <Link to={`/qrtypes/add`} className="btn btn-primary mr-2 h-75">Add Qr Code Type</Link>
                        <span className="mr-2">
                            <SelectDropdown
                                isSingle={true}
                                id="statusDropdown"
                                displayValue="name"
                                placeholder="Select Status"
                                className="mr-2"
                                intialValue={filter.status}
                                result={e => handleFilters({ status: e.value })}
                                options={statusModel.list}
                            />
                        </span>
                        <SelectDropdown
                            isSingle={true}
                            id="statusDropdown"
                            displayValue="name"
                            placeholder="All Categories"
                            intialValue={filter.category_id}
                            result={e => handleFilters({ category_id: e.value })}
                            options={category}
                        />
                        <button className="btn btn-primary h-75 ml-2" onClick={e => handleExport()}>Export</button>
                        {filter?.status || filter?.category_id || filter?.search ?
                            <button className="btn btn-secondary ml-2 h-75" onClick={e => clearAllFilters()}>Reset</button>
                            : null}
                    </div>
                </div>
                <div className="mt-2">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Image</th>
                                <th scope="col">Type</th>
                                <th scope="col">Date</th>
                                <th scope="col">Category</th>
                                <th scope="col">Status</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ?
                                <tr>
                                    <td colSpan={5} className="text-center">
                                        <img src="/assets/img/loader.gif" className="pageLoader" />
                                    </td>
                                </tr>
                                :
                                <>
                                    {data && data.map((item, index) => {
                                        return (
                                            <tr onDragStart={(e) => dragStart(e, index)} onDragEnter={(e) => dragEnter(e, index)} onDragEnd={e => drop(e)} key={index} draggable>
                                                <td>
                                                    {item?.image ?
                                                        <img src={`${environment.api}images/qrcodetypes/${item?.image}`} width="50px" height="50px" />
                                                        :
                                                        <img src='/assets/img/placeholder.png' width="50px" height="50px" />
                                                    }
                                                </td>
                                                <td className="text-capitalize">{item?.type}</td>
                                                <td>{moment(item?.createdAt).format('DD MMM YYYY')}</td>
                                                <td className="text-capitalize">{item?.category_details?.name}</td>
                                                <td><div className={`user_hours ${item.status}`} onClick={() => statusChange(item)}>
                                                    <span className='contract text-capitalize pointer'>
                                                        {item.status == 'deactive' ? 'inactive' : 'active'}
                                                    </span>
                                                </div></td>
                                                <td>
                                                    <div className="action_icons">
                                                        <a className='edit_icon pointer' title="Edit" onClick={e => editType(item?.slug)}>
                                                            <i class="material-icons edit" title="Edit">edit</i>
                                                        </a>
                                                        <span className='edit_icon pointer' onClick={e => setdeleteId(item._id)} data-toggle="modal" data-target="#exampleModal">
                                                            <i class="material-icons delete text-danger" title='Delete'> delete</i>
                                                        </span>
                                                        <span className='edit_icon pointer ml-2'>
                                                            <i class="material-icons delete" title='Drag'> pan_tool</i>
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </>
                            }
                        </tbody>
                    </table>
                </div>
                {!loading && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}
                {
                    !loading && total > filter.count ? <div className='paginationWrapper'>
                        <span>Showing {data?.length} from {total} records</span>
                        <Pagination
                            currentPage={filter.page}
                            totalSize={total}
                            sizePerPage={filter.count}
                            changeCurrentPage={pageChange}
                        />
                    </div> : <></>
                }
            </Layout>

            {/* Delete Modal */}
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <button type="button" class="close" id="CloseDeleteModal" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className='text-center mt-4'>
                                <p>Are you sure you want to delete this?</p>
                                <button type="button" class="btn btn-danger" onClick={e => deleteType(deleteId)}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Html;