import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";

const SupportDetail = () => {
    const { id } = useParams()
    const [detail, setdetail] = useState()
    const history = useHistory()

    useEffect(() => {
        getDetail()
    }, [])

    const getDetail = () => {
        loader(true)
        ApiClient.get(`contact?id=${id}`).then(res => {
            if (res.success) {
                setdetail(res.data)
            }
            loader(false)
        })
    }

    return (
        <Layout>
            <div className="d-flex justify-content-between align-items-center">
                <h1>Support Email Detail</h1>
                <i class="fa fa-arrow-left mr-2 h5" aria-hidden="true" title="Back" onClick={e => { history.goBack() }}></i>
            </div>
            <div className="row mt-4">
                <div className="col-md-6 mt-3">
                    <label className="fs-6"><b>FirstName:</b> {detail?.firstName}</label>
                </div>
                <div className="col-md-6 mt-3">
                    <label className="fs-6"><b>LastName:</b> {detail?.lastName}</label>
                </div>
                <div className="col-md-6 mt-3">
                    <label className="fs-6"><b>Email:</b> {detail?.email}</label>
                </div>
                <div className="col-md-6 mt-3">
                    <label className="fs-6"><b>Contact Number:</b> {detail?.phone}</label>
                </div>
                <div className="col-md-12 mt-3">
                    <label className="fs-6"><b>Subject:</b> {detail?.subject}</label>
                </div>
                <div className="col-md-12 mt-3">
                    <label className="fs-6"><b>Description:</b> {detail?.description}</label>
                </div>
            </div>
        </Layout>
    )
}

export default SupportDetail