import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import Select from "react-select"
import environment from "../../environment";

const AddEditQrCodeType = () => {
    const { id } = useParams()
    const [form, setform] = useState({ type: '', image: '', headerImg: '' })
    const [categorys, setcategorys] = useState()
    const history = useHistory()

    const uploadImage = (e,path) => {
        if(path == 'logoimg') setform({ ...form, baseImg: e.target.value })
        else setform({ ...form, headerBaseImg: e.target.value })
        let files = e.target.files
        let file = files.item(0)
        loader(true)
        ApiClient.postFormData('upload/image?modelName=qrcodetypes', { file: file, modelName: 'qrcodetypes' }).then(res => {
            if (res.success) {
                let image = res.data.fullpath
                if(path == 'logoimg') setform({ ...form, image: image, baseImg: '' })
                else setform({ ...form, headerImg: image, baseImg: '' })
            } else {
                setform({ ...form, baseImg: '' })
            }
            loader(false)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if(!form?.image){
            toast.error('Logo image is required')
            return
        }
        let payload = {
            ...form,
            category_id: form?.category_id?.value
        }
        if (id) {
            payload = {
                ...payload,
                id: form?.id
            }
        }
        if (!id) {
            ApiClient.post(`qrtype`, payload).then(res => {
                if (res.success) {
                    setTimeout(() => {
                        toast.success(res.message)
                    }, 100);
                    history.push(`/qrtypes`)
                }
            })
        } else {
            ApiClient.put(`qrtype`, payload).then(res => {
                if (res.success) {
                    setTimeout(() => {
                        toast.success(res.message)
                    }, 100);
                    history.push(`/qrtypes`)
                }
            })
        }
    }

    useEffect(() => {
        if(!id) getCategory()
        getDetail('')
    }, [])

    const getDetail = () => {
        if (id) {
            loader(true)
            ApiClient.get(`qrtype?id=${id}`).then(res => {
                if (res.success) {
                    setform(res.data)
                    getCategory(res.data)
                }
                loader(false)
            })
        }
    }

    const getCategory = (data) => {
        if(id) loader(true)
        ApiClient.get(`common-category/all?type=qrcode`).then(res => {
            if (res.success) {
                setcategorys(res.data.map(item=>{
                    return ({ value: item?._id, label: item?.name })
                }))
                if(data){
                    let values = { ...data }
                    res.data.filter(itm=>{
                        if(itm._id == data.category_id){
                            values.category_id = { value:itm?._id, label: itm?.name }
                        }
                    })
                    setform(values)
                }
            }
            if(id) loader(false)
        })
    }

    return (
        <>
            <Layout>
                <div className="">
                    <h1>{!id ? 'Add' : 'Edit'} Qr Code Type</h1>
                </div>
                <form className="mt-5" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <label>Qr Code Type <span className="text-danger">*</span></label>
                            <input type='text' value={form?.type} onChange={e => setform({ ...form, type: e.target.value })} className='form-control' placeholder='Enter Type' required />
                        </div>
                        <div className="col-md-6">
                            <label>Category <span className="text-danger">*</span></label>
                            <Select
                                value={form?.category_id}
                                onChange={e => {setform({ ...form, category_id: e })} }
                                options={categorys}
                                required
                            />
                        </div>
                        <div className="col-md-6 mt-3">
                            <label>Logo Image</label>
                            <div className='maininutcls'>
                                <label className="profileImageLabel">
                                    {form?.image?
                                    <img src={`${environment?.api}images/qrcodetypes/${form?.image}`} className="profileImage" />
                                    :
                                    <img src='/assets/img/placeholder.png' className="profileImage" />
                                    }
                                </label>
                                <div className='profile_btn'>
                                    <div>
                                        <label className="btn btn-primary edit ml-3">
                                            <input id="bannerImage" type="file" className="d-none" accept="image/*" value={form.baseImg || ''} onChange={(e) => { uploadImage(e,'logoimg'); }} />{form.image ? 'Change' : 'Upload'} Image</label>
                                    </div>
                                    <div>
                                        {form.image ? <label className="btn btn-primary delete ml-3" onClick={e => setform({ ...form, image: "" })}>Remove Image</label> : <></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <label>Header Image</label>
                            <div className='maininutcls'>
                                <label className="profileImageLabel">
                                    {form?.headerImg?
                                    <img src={`${environment?.api}images/qrcodetypes/${form?.headerImg}`} className="profileImage" />
                                    :
                                    <img src='/assets/img/placeholder.png' className="profileImage" />
                                    }
                                </label>
                                <div className='profile_btn'>
                                    <div>
                                        <label className="btn btn-primary edit ml-3">
                                            <input id="bannerImage1" type="file" className="d-none" accept="image/*" value={form?.headerBaseImg || ''}  onChange={(e) => { uploadImage(e,'headerImg'); }} />{form.headerImg ? 'Change' : 'Upload'} Image</label>
                                    </div>
                                    <div>
                                        {form.headerImg ? <label className="btn btn-primary delete ml-3" onClick={e => setform({ ...form, headerImg: "" })}>Remove Image</label> : <></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 text-right">
                            <button type="button" className="btn btn-secondary discard mr-2" onClick={e=>{ history.goBack() }}>Back</button>
                            <button type="submit" className="btn btn-primary ml-2">{!id?'Save':'Update'}</button>
                        </div>
                    </div>
                </form>
            </Layout>
        </>
    )
}

export default AddEditQrCodeType