import React, { useState } from "react";
import { useEffect } from "react";
import Layout from "../../components/global/layout"
import ApiClient from "../../methods/api/apiClient";
import Pagination from "react-pagination-js";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";

const Support = () => {
    const [data, setdata] = useState()
    const [filter, setfilter] = useState({ page: 1, count: 20, search: '' })
    const [total, settotal] = useState(0)
    const [loading, setloading] = useState(false)
    const history = useHistory()
    const [reply, setreply] = useState()
    const [replyId, setreplyId] = useState()

    useEffect(() => {
        getData()
    }, [])

    const getData = (p = {}) => {
        let fil = { ...filter, ...p }
        setloading(true)
        ApiClient.get(`allData`, fil).then(res => {
            if (res.success) {
                setdata(res?.data)
                settotal(res?.total)
            }
            setloading(false)
        })
    }

    const pageChange = (e) => {
        setfilter({ ...filter, page: e });
        getData({ page: e });
    }

    const handleReply = (e) => {
        e.preventDefault()
        ApiClient.put(`contact`, { id: replyId, reply: reply }).then(res => {
            if (res.success) {
                toast.success(res.message)
                getData()
                setreply('')
                document.getElementById('CloseReplyModal').click()
            }
        })
    }

    return (
        <Layout>
            <div>
                <h1>Support Emails</h1>
            </div>
            <table className="table mt-2">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Contact Number</th>
                        <th scope="col">Date</th>
                        <th scope="col">Replied</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                {loading ? null :
                    <tbody>
                        {data && data.map(item => {
                            return <tr>
                                <td scope="row" className="pointer" onClick={e => { history.push(`/support/detail/${item?.id}`) }}>{item?.firstName} {item?.lastName}</td>
                                <td>{item?.email}</td>
                                <td>{item?.phone || '--'}</td>
                                <td>{moment(item?.createdAt).format('DD MMM YYYY')}</td>
                                <td><span className={`user_hours ${item?.status}`}>{item?.status == 'active'?'Yes':'No'}</span></td>
                                <td>
                                    <div className="action_icons">
                                        <a className='edit_icon pointer' title="View" onClick={e => { history.push(`/support/detail/${item?.id}`) }}>
                                            <i class="material-icons view" title="View">visibility</i>
                                        </a>
                                        <a className='edit_icon pointer' title="Reply" onClick={e => setreplyId(item?.id)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            <i class="material-icons view" title="Reply">reply</i>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                }
            </table>
            {!loading && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}
            {
                !loading && total > filter.count ? <div className='paginationWrapper'>
                    <span>Show {filter.count} from {total} records</span>
                    <Pagination
                        currentPage={filter.page}
                        totalSize={total}
                        sizePerPage={filter.count}
                        changeCurrentPage={pageChange}
                    />
                </div> : <></>
            }
            {loading ? <div className="text-center py-4">
                <img src="/assets/img/loader.gif" className="pageLoader" />
            </div> : <></>}

            {/* Reply Modal */}
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" data-backdrop="static" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <form onSubmit={handleReply}>
                            <div class="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <label>Reply</label>
                                        <textarea value={reply} onChange={e => setreply(e.target.value)} className="form-control" id="exampleFormControlTextarea1" required placeholder="Reply.." rows="3"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" className="btn btn-secondary" id="CloseReplyModal" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                                <button type="submit" class="btn btn-primary">Send</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Support