import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { login_success } from '../../actions/user';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import { Link } from 'react-router-dom';
import './style.scss';
import { toast, ToastContainer } from 'react-toastify';

const Login = () => {
  const history = useHistory();
  const user = useSelector(state => state.user)
  useEffect(() => {
    if (localStorage.getItem('token')) {
      history.push('/dashboard')
    }
  }, [])

  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [remember, setRemember] = useState(false);
  const [password, setPassword] = useState('');
  const [eyes, setEyes] = useState({ password: false });

  useEffect(() => {
    loader(true)
    setTimeout(() => {
      loader(false)
    }, 500);
    let r = localStorage.getItem('remember')
    if (r) {
      let data = JSON.parse(r)
      setUsername(data.email)
      setPassword(data.password)
      setRemember(true)
    }
  }, [])

  const hendleSubmit = (e) => {
    e.preventDefault()
    const data = {
      email: username,
      password
    };
    loader(true)
    ApiClient.post('admin/signin', data).then(res => {
      loader(false)
      if (res.success == true) {
        if (remember) {
          localStorage.setItem('remember', JSON.stringify(data))
        } else {
          localStorage.removeItem('remember')
        }
        // toast.success(res.message)
        localStorage.setItem('token', res.data.access_token)
        dispatch(login_success(res.data))
        let url='/dashboard'
        history.push(url);
      }
    })
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-4 px-0">
            <div className='banner_img'>
              <div className='loginLeftImg'>
                {/* <h3>Multipurpose <br /> tool you need to succeed <br /> in business</h3> */}
              </div>
            </div>
          </div>
          <div className="col-md-8 p-0">
            <div className='right_side'>
              <form
                className="centerLogin"
                onSubmit={hendleSubmit}
              >
                <div className="text-center mb-4">
                  <h3 className="text-left lgtext">Sign in</h3>
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control mb-0 bginput" placeholder='Email address'
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <div className="inputWrapper">
                    <input
                      type={eyes.password ? 'text' : 'password'}
                      className="form-control mb-0 bginput"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      placeholder="Password"
                      required
                    />
                    <i className={eyes.password ? 'fa fa-eye' : 'fa fa-eye-slash'} onClick={() => setEyes({ ...eyes, password: !eyes.password })}></i>
                  </div>
                </div>
                <div className="forget">
                  <Link to="/forgotpassword" className="text-primary">Forgot Password ?</Link>
                </div>
                <div className="mt-0">
                  <label><input type="checkbox" checked={remember} onChange={(e) => setRemember(e.target.checked)} className="mr-2" /> Remember Me</label>
                  <button type="submit" className="btn btn-primary loginclass mb-4 mt-3">
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};

export default Login;